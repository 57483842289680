<template>
    <div class="DialogInDistribute">
        <div class="bodyClass">
            <li>
                <div class="div1">内分发给：</div>

                <div class="div2">
                    <span v-for="(item,index) in selectedArry" :key="index" class="showSpan">{{item.name}}</span>
                </div>
                <div class="rightSpan" @click="showModel=true">
                    选择内分发人
                    <i class="iconfont icon-page-right"></i>
                </div>
            </li>
            <template v-if="isUndistributed">
                <li>
                    <span>标记为已分发</span>
                    <div class="rightDiv">
                        <van-switch v-model="distributedMark" />
                    </div>
                </li>
                <li>
                    <span>此邮件发件人再来信自动分发给以上人员</span>
                    <div class="rightDiv">
                        <van-switch v-model="distributedAuto" />
                    </div>
                </li>
            </template>

            <li>
                <span>提醒接收人员</span>
                <div class="rightDiv">
                    <van-switch v-model="checked" />
                </div>
            </li>
            <div class="textClass">
                <van-cell-group>
                    <van-field v-model="textarea" type="textarea" placeholder="请输入" rows="6" autosize />
                </van-cell-group>
            </div>

        </div>
        <div class="footClass" @click="submit()">确定</div>
        <personnel-selection ref="PersonnelSelection" v-if="showModel" :showModel="showModel" :optionsList="optionsList" :selectedArry="selectedArry" @closePersonnelSelect="closePersonnelSelect" @toSearch="toSearch"></personnel-selection>
    </div>

</template>
<script>
import PersonnelSelection from '../PersonnelSelection'
import titleMixin from '@/mixin/title'
import { mapGetters } from 'vuex'
export default {
    name: 'DialogInDistribute',
    title: '内分发',
    mixins: [titleMixin],
    data() {
        return {
            mIds: [],
            checked: false,
            distributedMark: true,
            distributedAuto: false,
            textarea: '',
            selectedArry: [],
            showModel: false,
            optionsList: [],
            optionsListShow: [],
            isUndistributed: false
        }
    },
    computed: {
        ...mapGetters('Mail', ['currentCtid'])
    },
    created() {

    },
    mounted() {
        this.pageInit()
    },
    beforeRouteLeave(to, from, next) {
        if (this.$refs.PersonnelSelection && this.showModel) {
            this.$refs.PersonnelSelection.submit()
            next(false)
        } else {
            next()
        }
    },
    methods: {
        closePersonnelSelect(selectedArry) {
            this.selectedArry = selectedArry
            this.showModel = false
        },
        pageInit() {
            if (this.$route.path == '/mail/DialogInDistribute') {
                this.Global.utils.rightMenu.clearMenu()
                let { mIds, selectedArry, isUndistributed = false } = this.$route.params
                this.mIds = mIds
                this.selectedArry = selectedArry || []
                this.isUndistributed = isUndistributed
                this.getPersonalData()
            }
        },
        // 提交
        async  submit() {
            const ctids = this.selectedArry.map(({ctid}) => ctid)
            if (ctids.length < 1) {
                this.$toast.fail('请选择分发人')
                return
            }
            if (this.checked && this.textarea == '') {
                this.$toast.fail('请填写提醒内容')
                return
            }
            try {
                let data = {
                    mId: this.mIds.join(','),
                    noticeFlag: this.checked,
                    content: this.textarea,
                    recCtid: ctids.join()
                }
                if (this.isUndistributed) {
                    delete data.mId
                    delete data.recCtid
                    Object.assign(data, {
                        mIds: this.mIds,
                        autoDist: this.distributedAuto,
                        assignMark: this.distributedMark ? 1 : 0,
                        recCtids: ctids
                    })
                }

                if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                    data.ctid = this.currentCtid
                } else {
                    delete data.ctid
                }

                let url = this.Global.config.apiBaseURL + this.Global.api.Mail[(this.isUndistributed ? 'mailPublicMailDistPost' : 'mailDistributePost')]
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('内分发成功')
                    // 目前跳转到详情
                    this.$router.back()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 获取页面数据
        async  getPersonalData() {
            try {
                let data = {
                    dataType: 'contact',
                    funType: 'all'
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.accountDropList_get
                let res = await this.axios.get(url, { params: data })

                if (res.data.code.toString() == this.Global.config.RES_OK &&
                    this.Global.utils.isArray(res.data.data)) {
                    let data = res.data.data
                    let list = []
                    for (let i = 0; i < data.length; i++) {
                        list.push({
                            name: data[i].realName,
                            ctid: data[i].ctId
                        })
                    }
                    this.optionsList = list
                    this.optionsListShow = list
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        toSearch(value) {
            if (value.toString() == '') {
                this.optionsList = this.optionsListShow
                return
            }
            let list = []
            this.optionsListShow.forEach(element => {
                if (element.name.indexOf(value) != -1) {
                    list.push(element)
                }
            })
            this.optionsList = list
        }
    },
    components: {
        'personnel-selection': PersonnelSelection
    },
    watch: {
        $route() {
            this.pageInit()
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
